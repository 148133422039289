import ModelBase from './shared/model-base.js'

const OPTIONS = {
  i18nModelName: 'obj_vr/scene',
  columns: ['id', 'obj_vr_id', 'name', 'ori_pano_image', 'images']
}

export default class ObjVrScene extends ModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }
}
